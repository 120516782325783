
import {defineComponent} from 'vue';
import Button from "@/components/Button.vue";
import Input from '@/components/Input.vue';
import {ObjectIdResponse} from '@/types'
import {MutationTypes} from '@/store/mutation-types';
import MainText from "@/components/MainText.vue";
import SelectComponent from "@/components/SelectComponent.vue";
import ISelectOption from "@/types/ISelectOption";
import {IParcelLocker} from "@/types/Responses";

export default defineComponent({
  name: 'SmsNumberView',
  data() {
    return {
      smsNumber: '',
      validSmsNumber: true,
      smsSentAgain: false,
      errorMessage: '',
      selectedDepositor: null as ISelectOption | null,
      validated: false,
      depositorOptions: [] as IParcelLocker[],
    }
  },
  components: {
    SelectComponent,
    Button,
    Input,
    MainText,
  },
  computed: {
    phoneNumber(): number {
      return this.$store.getters.phoneNumber;
    },
    parcelLockers(): IParcelLocker[] {
      return this.$store.getters.parcelLockers;
    },
    preparedDepositorOptions(): ISelectOption[] {
      let preparedOptions: ISelectOption[] = [];

      this.parcelLockers.forEach((option) => {
        const preparedOption: ISelectOption = {
          value: option.id,
          label: option.name,
        }

        preparedOptions.push(preparedOption);
      });

      return preparedOptions;
    }
  },
  methods: {
    showErrorMessage(): void {
      if (!this.selectedDepositor) {
        this.$toast.warning('Wybierz depozytor!');
      }

      if (!this.smsNumber || !this.smsNumber.length) {
        this.validSmsNumber = false;
        this.$toast.warning('Wprowadź kod SMS');
      }
    },
    checkSmsNumber(): void {
      this.validated = true;

      if (this.smsNumber?.length && this.selectedDepositor) {
        this.$apiController.smsCodeVerification(this.$store.getters.orderId, {
          code: this.smsNumber,
          parcel_locker_id: this.selectedDepositor.value
        })
            .then((res: ObjectIdResponse): void => {
              let messageCode = '';

              if (res instanceof Response) {
                if (res?.status === 400) {
                  if (res?.body) {
                    this.$apiController.readResponseBody(res.body).then((res: string) => {
                      messageCode = JSON.parse(res)?.messages[0]?.code;
                      this.$toast.error(this.message(messageCode));
                    });
                  } else {
                    this.$toast.error(this.message(messageCode));
                  }
                  this.validSmsNumber = false;
                } else {
                  throw new Error('Bad response status');
                }
              } else {
                this.validSmsNumber = true;
                this.$store.commit(MutationTypes.SET_OPEN_CODE, res.id);
                this.$router.push({name: 'finalInformation'});
              }
            })
            .catch((): void => {
              this.validSmsNumber = false;
              this.$toast.error('Wpisany kod nie jest prawidłowy, spróbuj ponownie');
            });
      } else {
        this.showErrorMessage();
      }
    },

    sendSmsNumberAgain(): void {
      this.smsSentAgain = true;

      this.$apiController.sendSmsCodeAgain(this.$store.getters.userData.emailKey, {})
          .then((res: ObjectIdResponse): void => {
            this.smsSentAgain = true;
            this.$toast.success('Kod SMS został ponownie wysłany !');
            this.$store.commit(MutationTypes.SET_ORDER_ID, res.id);
          })
          .catch((): void => {
            this.$toast.error('Wysyłanie kodu SMS nie powiodło się. Spróbuj jeszcze raz lub skontaktuj się z adminstratorem.');
          });
    },

    message(code: string): string {
      switch (code) {
        case 'ORDER_VERIFICATION_CODE_NOT_EQUAL':
          this.errorMessage = 'Wpisany kod nie jest prawidłowy, spróbuj ponownie';

          break;
        case 'ORDER_VERIFICATION_CODE_EXPIRED':
          this.errorMessage = 'Ważność wprowadzonego kodu wygasła';

          break;
        case 'ORDER_VERIFICATION_BOOKING_NOT_POSSIBLE':
          this.errorMessage = 'Czas zlecenia już minął';

          break;
        default:
          this.errorMessage = 'Wpisany kod nie jest prawidłowy, spróbuj ponownie';
      }

      return this.errorMessage;
    }
  }
});
