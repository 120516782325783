import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  // [MutationTypes.SET_COUNTER](state: S, payload: number): void,
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_PHONE_NUMBER](state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  [MutationTypes.SET_ORDER_ID](state, orderId) {
    state.orderId = orderId;
  },
  [MutationTypes.SET_OPEN_CODE](state, openCode) {
    state.openCode = openCode;
  },
  [MutationTypes.SET_USER_DATA](state, response) {
    state.userData = response;
  },
  [MutationTypes.SET_PARCEL_LOCKERS](state, response) {
    state.parcelLockers = response;
  },
};
