
import {defineComponent} from 'vue';
import MainText from "@/components/MainText.vue";
import formatDate from "@/helpers/helpers";

export default defineComponent({
  name: 'FinalInformationView',
  components: {
    MainText
  },
  methods: {
    goToIntegne(): void {
      window.open("https://integne.pl");
    },
  },
  computed: {
    // TODO poprawnie pobierać dane
    openTime(): string {
      return '48';
    },
    date(): string {
      return formatDate(this.$store.getters.userData.create, 'date');
    },
    time(): string {
      return formatDate(this.$store.getters.userData.create, 'time');
    },
    pickUpOnly(): boolean {
      return this.$store.getters.userData.pickUpOnly;
    },
  },
});
