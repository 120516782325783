
import {defineComponent} from "vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import {MutationTypes} from "@/store/mutation-types";
import {UserVerificationResponse} from '@/types'
import MainText from "@/components/MainText.vue";
import Loader from "@/components/Loader.vue";
import formatDate from "../helpers/helpers"

export default defineComponent({
  data() {
    return {
      errorMessage: '',
      loading: false,
    }
  },
  components: {
    PhoneNumber,
    MainText,
    Loader
  },
  computed: {
    firstName(): string {
      return this.$store.getters.userData.firstName;
    },
    date(): string {
      return formatDate(this.$store.getters.userData.create, 'date');
    },
    time(): string {
      return formatDate(this.$store.getters.userData.create, 'time');
    },
    pickUpOnly(): boolean {
      return this.$store.getters.userData.pickUpOnly;
    },
  },
  methods: {
    showErrorMessage(code: string): string {
      switch (code) {
        case 'ORDER_VERIFICATION_HAS_BEEN_ALREADY_ACCEPTED':
          this.errorMessage = 'Link został już użyty !';

          break;
        default:
          this.errorMessage = 'Coś poszło nie tak, spróbuj ponownie lub skontaktuj się z admistratorem.';
      }

      return this.errorMessage;
    }
  },
  mounted() {
    this.loading = true;

    this.$apiController.verification(this.$route.params.email)
        .then((res: UserVerificationResponse): void => {
          let messageCode = '';

          if (res instanceof Response) {
            if (res?.status === 400) {
              if (res?.body) {
                this.$apiController.readResponseBody(res.body).then((res: string) => {
                  messageCode = JSON.parse(res)?.messages[0]?.code;

                  this.$toast.error(this.showErrorMessage(messageCode));
                });
              } else {
                this.$toast.error(this.showErrorMessage(messageCode));
              }
            } else {
              throw new Error('Bad response status');
            }
          } else {
            this.$store.commit(MutationTypes.SET_USER_DATA, res);
          }
        })
        .catch((): void => {
          this.$router.push({name: 'notFound'});
          this.$toast.error('Coś poszło nie tak... Spróbuj ponownie lub skontaktuj się z administratorem. ');
        })
        .finally((): void => {
          this.loading = false;
        });
  },
  beforeMount() {
    if (!this.$route.params.email) {
      this.$router.push({name: 'notFound'});
    }
  }
});
