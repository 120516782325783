
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {defineComponent} from 'vue';
import ISelectOption from "@/types/ISelectOption";
import type { PropType } from 'vue'

const SelectComponent = defineComponent({
  name: 'SelectComponent',
  components: {
    VSelect
  },
  props: {
    error: {
      type: Boolean
    },
    options: Array as PropType<ISelectOption[]>
  },
  data() {
    return {
      selected: null as ISelectOption | null,
      focus: false
    }
  },
  methods: {
    onFocus() {
      this.focus = true;
    },
    onBlur() {
      this.focus = false;
    },
    onChange() {
      this.$emit("update:modelValue", this.selected);
    }
  },
  watch: {
    selected() {
      this.onChange();
    }
  }
});
export default SelectComponent;

