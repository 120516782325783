
import {defineComponent} from "vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  components: {
    Button,
  },
  methods: {
    goToIntegne(): void {
      window.open("https://integne.pl");
    },
    //
    // goToEmail(): void {
    //   this.$router.push({path: "/email/6lCRYmOw29bgxQMvI8yW3kuWhZV4mdnt"});
    // },
  },
});
